<template>
  <b-container class="form-container">
    <b-row class="px-1">
      <b-col class="py-4">
        <b-card class="mb-0">
          <b-link class="brand-logo w-100 d-flex justify-content-center">
            <b-img
              :src="appLogoDarkImage"
              alt="logo"
              class="img-fluid w-100"
            />
          </b-link>

          <b-card-title class="mb-1 text-center">
            2-factor authenticatie
          </b-card-title>
          <b-card-text class="mb-2 text-center">
            <b>Je familiebank begint hier</b>
          </b-card-text>

          <p class=" text-center">We hebben een code naar jouw mail toegestuurd</p>

          <validation-observer
            ref="accountRules"
            tag="form"
          >
            <!-- email -->
            <b-form-group
              label="MFA Code"
              label-for="MFA"
            >
              <validation-provider
                #default="{ errors }"
                name="MFA"
                rules="required"
              >
                <b-form-input
                  id="email"
                  v-model="code"
                  :state="errors.length > 0 ? false:null"
                  name="register-mfa"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </validation-observer>

          <b-row>
            <b-col class="d-flex justify-content-center">
              <b-button @click="verifyCode" variant="primary m-1">
                Verifiëren
              </b-button>
              <b-button @click="resendCode" variant="info m-1" :disabled="resending">
                {{ !resending ? 'Opnieuw versturen': `Verstuurd... (wacht ${timerCount} seconden)` }}
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>

import { ValidationProvider, ValidationObserver } from 'vee-validate'

import { $themeConfig } from '@themeConfig'

export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "SignUp"
  },
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data() {
    return {
      fName: "",
      lName: "",
      resending: false,
      verifying: false,
      code: "",
      password: "",
      repeatPassword: "",
      submitStatus: null,
      invitation: "",
      user: null,
      timerCount: 60,
    };
  },

  computed: {
    
  },

  mounted() {
    
  },

  setup() {
    const { appLogoDarkImage } = $themeConfig.app
    return {
      appLogoDarkImage
    }
  },
  methods: {
    resendCode() {
      let _this = this;
      this.$http.post("/mfa/resend").then(() => {
        _this.countDown()
      });
    },

    verifyCode() {
      let _this = this;
      this.$http.post("/mfa/verify", {
        code: this.code
      }).then((response) => {
        if(response.status === 200) {
          console.log("res",response)
          _this.$router.push({name:"overview"}, () => this.$router.go(0));
        }       
      }).catch((err) => {
        this.countDown()
        console.log("error", err)
      });
    },
    countDown() {
      if(this.timerCount > 0) {
        this.resending = true
        setTimeout(()=>{
          this.timerCount--;
          this.countDown();
        },1000)    
      } else {
        this.resending = false;
        this.timerCount = 30
      }
    }
  }
};
</script>
<style scoped>

.form-container {
  max-width: 500px;
}

.spinner.sm {
  height: 2em;
  width: 2em;
}

</style>


